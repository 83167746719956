<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end class="poppinsregular">
      <v-flex xs12 pa-4 align-self-center text-left>
        <span class="poppinsbold" style="font-size:20px">Attendance Report</span>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <!-- <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
        <v-select
          v-model="selectedFilter"
          :items="employeeList"
          item-text="userName"
          item-value="_id"
          outlined
          dense
          clearable
          label="Type"
        ></v-select>
      </v-flex> -->
      <!-- <v-flex xs3 align-self-center>
        <v-spacer></v-spacer>
      </v-flex> -->
      <!-- <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="attendanceData.length > 0"
      >
        <a
          target="_blank"
          :href="
            'https://api.wayanadwildlifesanctuary.com/purchase/getDetailedReportPdf?from=' +
            fromDate +
            '&to=' +
            toDate +
            '&filter=' +
            selectedFilter
          "
          download
          class="downlink"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
            "
          >
            Download PDF</span
          >
        </a>
      </v-flex>
      <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="attendanceData.length > 0"
      >
        <download-excel :data="json_data" :fields="json_fields">
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex> -->
    </v-layout>
    <v-layout wrap fill-height v-if="attendanceData" class="poppinsregular">
      <v-flex xs12 pt-4 v-if="attendanceData.length > 0">
        <v-layout wrap fill-height>
          <v-flex xs12 sm6 pa-2 v-for="(item, i) in attendanceData" :key="i">
            <v-layout wrap fill-height style="border:2px solid #CCC">
              <v-flex xs12>
                <v-card tile flat   class="rounded-lg">
                  <v-layout
                    wrap
                    fill-height
                    v-if="item.attandance.length > 0"
                    pa-4
                  >
                    <v-flex xs12 pa-2 align-self-center text-right>
                     <i><span class="poppinssemibold">
                        Date : {{ formatDate(item._id) }}
                      </span></i> 
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      v-for="(sub, j) in item.attandance"
                      :key="j"
                    >
                      <v-layout wrap>
                        <v-flex xs8 pa-1 text-left align-self-center>
                          <span v-if="sub.empId">
                            {{ sub.empId.userName }}
                          </span>
                        </v-flex>
                        <v-flex xs4 pa-1 text-left align-self-center>
                          <span v-if="sub.isPresent">
                            <v-btn block small tile color="primary">
                              Present
                            </v-btn>
                          </span>
                          <span v-else>
                            <v-btn block small tile color="error">
                              {{ sub.leaveType }}
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-4 v-else>
        <span style="font-family: poppinsregular">
          No Attendance report found!!
        </span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showDesc" max-width="900px">
      <v-card>
        <v-card-title>
          <span style="font-family: poppinsbold" v-html="heads"></span>
        </v-card-title>

        <v-card-subtitle style="padding: 5%">
          <span v-html="description"></span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showDesc = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  // props: ["booking", "pages", "count"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      curItem: null,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
      cottageTotal: 0,
      attendanceData: [],
      pages: 0,
      count: 12,
      employeeList: null,

      selectedFilter: null,
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      showDesc: false,
      description: "",
      heads: "",

      excel: [],
      json_fields: {
        Order_No: "txnid",
        Invoice_No: "receiptno",
        "Customer Name": "payment.name",
        "Item Name": "product.name",
        "Item Type": "unitname",
        "Item Size": "sizename",
        "Quantity Purchased": "quantity",
        "Phone No": "payment.deliveryaddress.phone",
        Email: "payment.deliveryaddress.email",
        Address: "payment.deliveryaddress.streetAddress",
        "Date of placing of order": "payment.init_date",
        "Order Dispatched On": "shipped_date",
        "Courier Service": "courierservice",
        "Tracking ID": "trackingid",
        "Order Delivered on": "delivered_date",
        "Amount settled(total-PG)": "amountWithoutPG",
        "Amount Paid(Total)": "total",
      },
      json_fieldsReservation: {
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        "Seats Reserved": "reserved",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_dataReservation: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    // currentPage() {
    //   this.getData();
    // },
    // selectedFilter() {
    //   if (this.currentPage > 1) this.currentPage = 1;

    //   this.fromDate = null;
    //   this.toDate = null;
    //   this.getData();
    // },
    fromDate() {
      // this.selectedFilter = null;
      this.getData();
    },
    toDate() {
      // this.selectedFilter = null;
      this.getData();
    },
  },
  beforeMount() {
    // this.getEmp();
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 3 * 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  methods: {
    showPopup(heads, item) {
      this.description = item;
      this.heads = heads;
      this.showDesc = true;
    },
    getEmp() {
      this.appLoading = true;
      axios({
        url: "/get/all/employeelist",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/attandance/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          count: this.count,
          page: this.currentPage,
          empId: this.selectedFilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.attendanceData = response.data.data;
          this.excel = response.data.data;
          this.json_data = this.excel;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(item);
      var year = dt.getFullYear();
      var day = dt.getUTCDay();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year + ", " + days[day];
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
td {
  font-family: poppinsregular;
}

th {
  font-family: poppinssemibold;
}
</style>
